.container {
    padding: 16px;
}

.input,
.inputError {
    width: 45px;
    height: 45px;
    padding: 0;
    font-size: 24px;
    text-align: center;
    margin-right: 12px;
    text-transform: uppercase;
    color: #494949;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    background: #fff;
    background-clip: padding-box;
}

.input:focus {
    appearance: none;
    outline: 0;
    box-shadow: 0 0 0 3px rgb(16 64 59 / 70%);
}

.inputError:focus {
    appearance: none;
    outline: 0;
    box-shadow: 0 0 0 3px rgba(128, 23, 23, 0.7);
}
